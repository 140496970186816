<template>
  <div class="loan_item">
    <div class="inner inner1">
      <!-- <div class="content">
        <div class="left_cell">
          <h2>Are you in need of quick <br>and convenient cash? </h2>
          <p class="inner1_text">Look no further - LendMax is here to help you. We understand that unexpected expenses can arise at any time, and sometimes you just need a little extra financial support.
          </p>
        </div>
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div>
      </div> -->
    </div>
    <div class="inner inner2">
      <h1>About LendMax</h1>
      <div>
        <p>LendMax is the ultimate lending platform designed specifically for the people of Nigeria. If you need a personal loan
          to cover unexpected expenses, LendMax is here to provide you with the financial support you need.
        </p>
        <p>With LendMax, you can apply for a loan anytime, anywhere. No more endless paperwork or waiting in long queues
          at the bank. Our simple and user-friendly interface makes it easy for you to submit your application in just a few minutes.
          Just download the app, create an account, and start your loan application process.
        </p>
        <p>What sets LendMax apart from other loan apps is our commitment to flexibility and convenience. We understand that
          everyone’s financial situation is different, which is why we offer a wide range of loan options and repayment plans tailored
          to your needs. Whether you prefer a short-term loan with quick repayment or a longer-term loan with lower monthly
          installments, we have you covered.</p>
      </div>
    </div>
    <div class="inner inner3">
      <h1>Contact Us</h1>
      <h2>Our dedicated team of loan experts is ready to assist you with any questions or concerns you may have.
        Feel free to get in touch with us using the following contact information</h2>
      <div class="content flex jc-sb">
        <div v-for="(item,index) in inner_3items" :key="index" :style="{background: 'url(' + item.img + ')',backgroundSize:'100% 100%', width:'32%'}">
          <div class="item flex ai-c jc-sb">
            <div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.txt }}</p>
            </div>
            <img :src="item.img2">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_3items: [
        { title: 'Address:', txt: '50/52, TOYIN STREET, IKEJA, IKEJA, LAGOS STATE', img: require('@/assets/images/loanImg/innner3_bg2.png'), img2: require('@/assets/images/loanImg/loa.png') },
        { title: 'Email:', txt: 'feedback@lendmaxapp.com', img: require('@/assets/images/loanImg/innner3_bg1.png'), img2: require('@/assets/images/loanImg/email.png') },
        { title: 'Phone：', txt: '+234 09050857695', img: require('@/assets/images/loanImg/innner3_bg3.png'), img2: require('@/assets/images/loanImg/phone.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=lendmax.money.credit')
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    width:1400px;
    // padding:50px calc((100% - 1100px) / 2) 50px;
    margin: 0 auto;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
    height: 500px;
    background-size: 100% auto;
  }
  .inner2{
    // height: 420px;
    // background:#EEEEEE;
    padding-bottom: 50px;
    h1{
      font-size: 35px;
      text-align: center;
      margin:80px 0 30px;
    }
    div{
      padding: 0 250px;
      p{
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }
  }
  .inner3{
    background: #F5F5F5;
    padding: 50px 0 100px;
    h1{
      font-size: 40px;
      text-align: center;
      margin:60px 0 30px;
    }
    h2{
      text-align: center;
      padding: 0 250px;
      font-weight: normal;
      line-height: 30px;
    }
    h3{
      margin-bottom: 18px;
    }
    .content{
      margin-top: 80px;
      .item{
        background: #FFFFFF;
        opacity:0.9;
        margin: 180px 15px 20px 15px;
        padding: 20px 15px;
        height: 116px;
        img{
          width: 35px;
        }
        p{
          line-height: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    padding: 30px 0;
    background: white !important;
    color: #333;
    .inner{
      width: 100vw;
      min-width: calc(100vw) !important;
    }
    .inner1{
      height: 150px !important;
    }
    .inner2{
      width: calc(100vw);
      padding-bottom: 0;
      h1{
        line-height: 40px;
        margin: 0 0 30px;
        font-size: 25px;
      }
      div{
        padding: 0 20px;
        font-size: 14px;
      }
    }
    .inner3{
      padding: 20px 0 0;
      h1{
        margin: 20px 0;
      }
      h2{
        font-size: 16px;
        padding: 0 20px;
      }
      .content{
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-top: 50px;
        div{
          width: 80% !important;
          margin-bottom: 20px;
        }
        .item{
          margin: 150px 15px 15px 15px;
        }
      }
    }
  }
}
</style>
